const initialState = {
  searchResult: {},
  searchCriteria: {},
  sortFields: [],
};

export default function containerDashboardReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case 'SET_SEARCH_CRITERIA':
      return { ...state, ...action.payload };
    case 'SET_TD_SORT':
      return { ...state, ...{ sortFields: action.payload } };
    case 'SET_SEARCH_RESULT':
      return { ...state, ...action.payload, ...{ lastUpdateTs: new Date() } };
    case 'RESET_TD_SEARCH':
      return initialState;
    default:
      return state;
  }
}
