import React from 'react';
import { Modal } from 'react-bootstrap';
import { Digital } from 'react-activity';

import styles from './css/ActivityLoader.module.css';
import "react-activity/dist/library.css";

const ActivityLoader = (props) => (
  <Modal
    id="activityModal"
    show={props.show}
    centered
    className={
      props.uploadComp ? styles.uploadContainer : styles.modalContainer
    }
    onHide={() => {}}
  >
    <Digital size={50} color="#ffffff" />
  </Modal>
);

export default ActivityLoader;
