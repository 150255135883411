// import { createStore, applyMiddleware, compose } from 'redux';
// import rootReducer from './reducers';
// import thunk from 'redux-thunk';

// export default function configureStore() {
//   const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//   const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
//   return store;
// }

import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//import crossBrowserListener from './reduxpersist-listener';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers'; // the value from combineReducers
import thunk from 'redux-thunk';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['navigation', 'appinfo'],
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);
const config = {
  whitelist: ['SET_USER', 'RESET_USER', 'SET_ASSUME_USER', 'RESET_ASSUME_USER'],
};
const middlewares = [thunk, createStateSyncMiddleware(config)];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  pReducer,
  composeEnhancers(compose(applyMiddleware(...middlewares)))
);
initMessageListener(store);
//window.addEventListener('storage', crossBrowserListener(store, persistConfig));

export const persistor = persistStore(store);
