const initialState = {
  containers: [],
};

export default function containerListReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CONTAINERLIST':
      return { ...state, ...action.payload };
    case 'RESET_CONTAINERLIST':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
