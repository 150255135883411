import moment from 'moment';

export function setAppInfo(info) {
  return {
    type: 'SET_INFO',
    payload: info,
  };
}

export function setClientInfo(obj) {
  return {
    type: 'SET_USER_CLIENT',
    payload: obj,
  };
}

export function setUser(user) {
  return {
    type: 'SET_USER',
    payload: user,
  };
}

export function setLastAccessUser() {
  return {
    type: 'SET_LAST_ACCESS_USER',
    payload: { loginTimeStamp : moment().toDate()},
  };
}

export function resetUser() {
  return {
    type: 'RESET_USER',
  };
}

export function setAssumeUser(obj) {
  return {
    type: 'SET_ASSUME_USER',
    payload: obj,
  };
}

export function resetAssumeUser() {
  return {
    type: 'RESET_ASSUME_USER',
  };
}

export function setContainerList(list) {
  return {
    type: 'SET_CONTAINERLIST',
    payload: list,
  };
}
export function resetContainerList() {
  return {
    type: 'RESET_CONTAINERLIST',
  };
}

export function setSelectedFilters(filters) {
  return {
    type: 'SET_SELECTED_FILTERS',
    payload: filters,
  };
}

export function resetSelectedFilters() {
  return {
    type: 'RESET_SELECTED_FILTERS',
  };
}

export function setDropdowns(list) {
  return {
    type: 'SET_DROPDOWN',
    payload: list,
  };
}

export function resetDropdowns() {
  return {
    type: 'RESET_DROPDOWN',
  };
}
export function setVvdAllocationRepPort(list) {
  return {
    type: 'SET_VVD_REP_PORT',
    payload: list,
  };
}
export function resetVvdAllocationRepPort() {
  return {
    type: 'RESET_VVD_REP_PORT',
  };
}
export function setVvdAllocationRepVVD(list) {
  return {
    type: 'SET_VVD_REP_VVD',
    payload: list,
  };
}
export function resetVvdAllocationRepVVD() {
  return {
    type: 'RESET_VVD_REP_VVD',
  };
}
export function setVvdAllocationRepAccount(list) {
  return {
    type: 'SET_VVD_REP_ACCOUNT',
    payload: list,
  };
}
export function resetVvdAllocationRepAccount() {
  return {
    type: 'RESET_VVD_REP_ACCOUNT',
  };
}
export function resetConDashSearchCr() {
  return {
    type: 'RESET_TD_SEARCH',
  };
}
export function setConDashSearchCr(obj) {
  return {
    type: 'SET_SEARCH_CRITERIA',
    payload: obj,
  };
}
export function setConDashSortCr(obj) {
  return {
    type: 'SET_TD_SORT',
    payload: obj,
  };
}
export function setConDashSearchResult(obj) {
  return {
    type: 'SET_SEARCH_RESULT',
    payload: obj,
  };
}

export function resetBookingDashSearch() {
  return {
    type: 'RESET_BOOKING_SEARCH',
  };
}
export function setBookingDashSearchCr(obj) {
  return {
    type: 'SET_BOOKING_CRITERIA',
    payload: obj,
  };
}
export function setBookingDashSortCr(obj) {
  return {
    type: 'SET_BOOKING_SORT',
    payload: obj,
  };
}
export function setBookingDashSearchResult(obj) {
  return {
    type: 'SET_BOOKING_RESULT',
    payload: obj,
  };
}

export const setBookingCorrectionCriteria = (obj) => ({
  type: 'SET_BOOKING_CRITERIA',
  payload: obj,
});

export const setBookingCorrectionSort = (obj) => ({
  type: 'SET_BOOKING_SORT',
  payload: obj,
});

export const setBookingCorrectionResult = (obj) => ({
  type: 'SET_BOOKING_RESULT',
  payload: obj,
});

export const resetBookingCorrectionSearch = () => ({
  type: 'RESET_BOOKING_SEARCH',
});

export function resetVgmLegalInfo() {
  return {
    type: 'RESET_VGM_LEGAL_INFO',
  };
}

export function setVgmLegalInfo(obj) {
  return {
    type: 'SET_VGM_LEGAL_INFO',
    payload: obj,
  };
}
