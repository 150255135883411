const initialState = null;

export default function assumeuserReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_ASSUME_USER':
      return { ...state, ...action.payload };
    case 'RESET_ASSUME_USER':
      return null;
    default:
      return state;
  }
}
