const initialState = {
  username: '',
  //password: '',
  accessToken: '',
  tokenType: '',
  idToken: '',
  refreshToken: '',
  expiresIn: 0,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LAST_ACCESS_USER':
      return { ...state, ...action.payload };
    case 'SET_USER_CLIENT':
      return { ...state, ...action.payload };
    case 'SET_USER':
      return { ...state, ...action.payload };
    case 'RESET_USER':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
