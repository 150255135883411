const initialState = {
  version: '',
  instanceId: Math.random().toFixed(2) * 100,
};

export default function appInfo(state = initialState, action) {
  switch (action.type) {
    case 'SET_INFO':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
