import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import styles from './css/AlertDialog.module.css';

const getTitle = (props) => {
  if (props.title === 'Success') return props.t('alertDialog.success');
  else if (props.title === 'Confirm') return props.t('alertDialog.confirm');
  else if (props.title === 'Info') return 'Info';
  else return props.t('alertDialog.error');
};

const AlertDialog = (props) => (
  <Modal
    id="alertModal"
    show={props.show}
    centered
    className={styles.modalContainer}
    onHide={() => {}}
  >
    <div className={styles.alertCard}>
      <div className={styles.btnCloseDiv}>
        <i
          className={[styles.closeIcon, 'fa fa-times-circle-o'].join(' ')}
          onClick={props.didClose}
        />
      </div>
      <div className={styles.imgIconDiv}>
        <img
          className={styles.imgIcon}
          src={
            props.title === 'Success'
              ? require('./../assets/images/TickIcon.png').default
              : props.title === 'Confirm'
              ? require('./../assets/images/QuestionIcon.png').default
              : props.title === 'Info'
              ? require('./../assets/images/info.png').default
              : require('./../assets/images/errorIcon.png').default
          }
          alt="icon"
        />
      </div>
      <div className={styles.txtTitleDiv}>
        <span className={styles.txtTitle}>{getTitle(props)}</span>
      </div>
      <div className={styles.txtMsgDiv}>
        <span className={styles.txtMsg}>{props.msg}</span>
      </div>
      <div className={styles.btnButtonDiv}>
        {props.buttons ? (
          props.buttons.map((obj, index) => (
            <Button
              key={index}
              block
              className={styles.btnButton}
              onClick={obj.onPress}
            >
              {obj.title}
            </Button>
          ))
        ) : (
          <Button block className={styles.btnButton} onClick={props.didClose}>
            {props.t('alertDialog.btnOK')}
          </Button>
        )}
      </div>
      <div class="text-muted">Timestamp : {new Date().toUTCString()}</div>
    </div>
  </Modal>
);

export default withTranslation('common')(AlertDialog);
