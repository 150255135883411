import * as loglevel from 'loglevel';
import remote from 'loglevel-plugin-remote';
import { store } from './store';

const customJSON = (log) => {
  const state = store.getState();
  //console.log("state",state);
  const user = state.user.username;
  return {
    msg: log.message,
    level: log.level.label,
    stacktrace: log.stacktrace,
    timestamp: new Date().toString(),
    user: user,
  };
};
let loggerURL = process.env.REACT_APP_CARGO_API_URL + '/cargotrack/logger';
remote.apply(loglevel, {
  format: customJSON,
  url: loggerURL,
  stacktrace: { depth: 5 },
});
loglevel.enableAll();

// if (!__PROD__) {
//   loglevel.setLevel('debug')
// } else {
loglevel.setLevel('debug');
//}

export default loglevel;
