const initialState = {
  vvds: [],
  portOfLoad: [],
};

export default function dropdownReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_VVD_REP_PORT':
      return { ...state, ...{ portOfLoad: action.payload } };
    case 'RESET_VVD_REP_PORT':
      return { ...state, ...{ portOfLoad: [] } };
    case 'SET_VVD_REP_VVD':
      return { ...state, ...{ vvds: action.payload } };
    case 'RESET_VVD_REP_VVD':
      return { ...state, ...{ portOfLoad: [] } };
    case 'SET_VVD_REP_ACCOUNT':
      return { ...state, ...{ accountNames: action.payload } };
    case 'RESET_VVD_REP_ACCOUNT':
      return { ...state, ...{ portOfLoad: [] } };

    default:
      return state;
  }
}
