import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import common_en from './translations/en/common.json'; //English language
import common_zh from './translations/zh/common.json'; // Chinese language
import common_vi from './translations/vi/common.json'; // Vietnamese language

if (!localStorage.getItem('selectedLanguage')) {
  if (process.env.REACT_APP_COUNTRY_CODE === 'CN')
    localStorage.setItem('selectedLanguage', 'zh');
  else localStorage.setItem('selectedLanguage', 'en');
}

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: localStorage.getItem('selectedLanguage'), //"en", // default language to use
  resources: { en: { common: common_en }, zh: { common: common_zh },  vi: { common: common_vi }}
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
