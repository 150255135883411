import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import {
  resetUser,
  resetDropdowns,
  resetAssumeUser,
  resetSelectedFilters,
} from './../utils/actions';
import http from './../utils/http';
import styles from './css/Parent.module.css';

class Parent extends Component {
  componentDidMount() {
    //This will be called only when user kills and open new tab.
    //or user will refresh the window.
    //So checking user's session time before making API call.
    this.validateUserSession();
  }
  validateUserSession = () => {
    const { user } = this.props;
    let totalLoginDuration = moment.duration(
      moment().diff(moment(user.loginTimeStamp))
    );
    if (
      totalLoginDuration.asSeconds() > Number(user.expiresIn) &&
      user.expiresIn !== 0
    )
      this.didLogout(() =>
        this.props.history.push({
          pathname: `/signin`,
          state: { sessionExpired: true },
        })
      );
    //Token is expired and now user open close or refresh.
  };

  didLogout = (callback) => {
    const { user } = this.props;
    sessionStorage.removeItem('BDdays');
    sessionStorage.removeItem('BDvvd');
    sessionStorage.removeItem('BDendDt');
    sessionStorage.removeItem('BDstartDt');
    sessionStorage.removeItem('BDtxtBookingVal');
    sessionStorage.removeItem('BDchkEDIRef');
    sessionStorage.removeItem('BDchkBKG');
    sessionStorage.removeItem('CDdays');
    sessionStorage.removeItem('CDvvd');
    sessionStorage.removeItem('CDendDt');
    sessionStorage.removeItem('CDstartDt');
    sessionStorage.removeItem('CDtxtBookingVal');
    sessionStorage.removeItem('CDchkEDIRef');
    sessionStorage.removeItem('CDchkBKG');
    this.props.resetAssumeUser();
    this.props.resetUser();
    this.props.resetDropdowns();
    this.props.resetSelectedFilters();
    if (user.username !== '') {
      global.trackAnalyticsEvent(
        'Logout',
        `User logged out`,
        '',
        user.username
      );
      http.post(
        global.URL_Logout,
        { accessToken: user.accessToken, webid: user?.username },
        {},
        () => {},
        null,
        () => {}
      );
    }
    callback && callback();
  };

  render() {
    return <div className={styles.rootContainer}>{this.props.children}</div>;
  }
}

const ReduxConnectedCompoment = connect((state) => ({ user: state.user }), {
  resetUser,
  resetDropdowns,
  resetAssumeUser,
  resetSelectedFilters,
})(withRouter(Parent));

export default ReduxConnectedCompoment;
