import React from 'react';
import logger from '../utils/logger';
import styles from './css/NoMatch404.module.css';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  logErrorToMyService = (error) => {
    if (typeof error === 'object' && error && 'stack' in error) {
      let m = error.stack;
      if (m && m.length > 200) {
        m = m.substr(0, 200);
      }
      logger.error('React Error ', m);
    } else if (typeof error === 'object' && error && 'message' in error) {
      logger.error('React Error ', error.message);
    } else {
      logger.error('React Error ', error);
    }
  };
  reportToDev = () => {
    alert('Report');
  };
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('Common error ', error);
    console.log('Common errorInfo ', errorInfo);
    this.logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div className={styles.subComp}>
            <div className={styles.card}>
              <span className={styles.title}>Error</span>
              <span className={styles.text}>Something went wrong!</span>
              <span className={styles.link}>
                Take me back to the{' '}
                <Link to={'/'} href={'/'}>
                  {'Home Page'}
                </Link>
              </span>
              {/*
                   <span className={styles.link}>
                          <a  href={ this.reportToDev() }>
                            {'Report to Developer'}
                          </a>
                    </span>
          */}
            </div>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
