import queryString from 'query-string';
import _ from 'lodash';

export const DOCUMENT_TYPE_VGM_DETAILS = 'VG';
export const BL_DOC_PRIV_LOI_OT = 'OL';
export const BL_DOC_PRIV_LOI_BL = 'BL';
export const BL_DOC_PRIV_OBL_DL = 'OD';
export const BL_DOC_PRIV_SWB_DL = 'SD';
export const BL_DOC_PRIV_DE = 'DE';

export const removeNewlines = function (str) {
  str = str.replace(/, /g, ',');
  str = str.replace(/ ,/g, ',');
  str = str.replace(/\s{2,}/g, ',');
  str = str.replace(/\t/g, ',');
  str = str.replace(/\n/g, ',');
  str = str.replace(
    / /g,
    ','
  ); /* commeting out since space might be there in booking ref#*/
  str = str
    .toString()
    .trim()
    .replace(/(\r\n|\n|\r)/g, ',');
  if (str.endsWith(',')) str = str.substring(0, str.length - 1);
  return str;
};

export const refNumRemoveNewlines = function (str) {
  str = str.replace(/\s{2,}/g, ',');
  str = str.replace(/\t/g, ',');
  str = str.replace(/\n/g, ',');
  //  str = str.replace(/ /g, ','); /* commeting out since space might be there in booking ref#*/
  str = str
    .toString()
    .trim()
    .replace(/(\r\n|\n|\r)/g, ',');
  if (str.endsWith(',')) str = str.substring(0, str.length - 1);
  return str;
};

export const setNativeValue = function (element, value) {
  let lastValue = element.value;
  element.value = value;
  let event = new Event('input', { target: element, bubbles: true });
  // React 15
  event.simulated = true;
  // React 16
  let tracker = element._valueTracker;
  if (tracker) {
    tracker.setValue(lastValue);
  }
  element.dispatchEvent(event);
};

// First line can only be width other lines can be second width.
function wordwrapfirstline(str, width, secondwidth) {
  let brk = '\n';
  width = width || 75;
  let cut = true;
  if (!str) return str;

  let regex =
    '.{1,' +
    width +
    '}(\\s|$)' +
    (cut ? '|.{' + width + '}|.+$' : '|\\S+?(\\s|$)');
  let result = str.match(RegExp(regex, ''))[0];
  // console.log(str.substring(result.length));

  return (
    result.trim() +
    brk +
    wordwrap2Columns(str.substring(result.length), secondwidth)
  );
}

function wordwrap2Columns(str, width) {
  // let brk = '\n';
  width = width || 75;
  let cut = true;
  if (!str) return str;

  let regex =
    '.{1,' +
    width +
    '}(\\s|$)' +
    (cut ? '|.{' + width + '}|.+$' : '|\\S+?(\\s|$)');
  let result = str.match(RegExp(regex, 'g'));
  let output = '';
  for (let i = 0; i < result.length; i++) {
    // console.log('i' + i + ' =' + result[i] + ';');
    result[i] = result[i].trim();
    output += result[i];
    if (i === result.length - 1) {
      // do nothing
    } else if (i % 2 === 0) {
      output += ' ';
    } else {
      output += '\n';
    }
  }

  return output;
}

export const formatComDescription = function (commDesc) {
  // let i = 0;
  // let j = 0;
  let k = 0;
  // let commDesc = $('#commodityDescription').val();
  let newCommDesc = '',
    finalcommDesc = '';
  // let splittedString;
  newCommDesc = commDesc.split('\n');
  for (k = 0; k < newCommDesc.length; k++) {
    if (k === 0) {
      // first line can only be 30 characters
      if (newCommDesc[k].length <= 30) {
        // console.log('do nothing');
        finalcommDesc = finalcommDesc + newCommDesc[k] + '\n';
      } else {
        let firstline = wordwrapfirstline(newCommDesc[k], 30, 30);
        // console.log('firstline= ' + firstline);
        finalcommDesc = finalcommDesc + firstline + '\n';
      }
    } else {
      // console.log('original=' + newCommDesc[k]);
      // console.log('original=' + newCommDesc[k].length);
      if (newCommDesc[k].length <= 30) {
        // console.log('do nothing');
        finalcommDesc = finalcommDesc + newCommDesc[k] + '\n';
      } else {
        // console.log('wordwrap ' + wordwrap2Columns(newCommDesc[k], 30));
        finalcommDesc =
          finalcommDesc + wordwrap2Columns(newCommDesc[k], 30) + '\n';
      }
    }
  }
  // remove trailing '\n'
  finalcommDesc = finalcommDesc.substring(0, finalcommDesc.length - 1);
  //Added for D029259
  // if ($('#commodityDescription').val() != finalcommDesc) isBookingChanged = 'Y';
  // $('#commodityDescription').val(finalcommDesc);
  return finalcommDesc;

  // return commDesc;
};

export const formatComMarks = function (value, maxLength) {
  let valToPrint = '';
  let valArr = value.split('\n');
  let remainder = '';
  for (let i = 0; i < valArr.length; i++) {
    let lineLength = '';
    if (remainder !== '') {
      valToPrint = valToPrint + '\n' + remainder;
      remainder = '';
    }
    if (valArr[i].length > maxLength) {
      let firstTime = true;
      if (i !== 0) valToPrint = valToPrint + '\n';
      let lineArr = valArr[i].split(' ');
      for (let k = 0; k < lineArr.length; k++) {
        let word = remainder;
        if (lineLength !== '' && word !== '') word = lineLength + ' ' + word;
        else word = lineLength + word;

        if (word.length > maxLength) {
          if (firstTime) valToPrint = valToPrint + remainder;
          else valToPrint = valToPrint + '\n' + remainder;
          firstTime = false;
          remainder = '';
          lineLength = remainder;

          word = lineArr[k];
          if (lineLength !== '' && word !== '') word = lineLength + ' ' + word;
          else word = lineLength + word;

          if (word.length > maxLength) {
            if (lineArr[k].length > maxLength) {
              let count = Math.floor(lineArr[k].length / maxLength);
              for (let j = 1; j <= count; j++) {
                if (firstTime)
                  valToPrint = valToPrint + lineArr[k].substr(0, maxLength);
                else
                  valToPrint =
                    valToPrint + '\n' + lineArr[k].substr(0, maxLength);
                firstTime = false;
                lineLength = lineArr[k].substr(0, maxLength);
                lineArr[k] = lineArr[k].substr(maxLength);
              }
              remainder = lineArr[k];
            } else {
              if (firstTime) valToPrint = valToPrint + lineArr[k];
              else valToPrint = valToPrint + '\n' + lineArr[k];
              firstTime = false;
              lineLength = lineArr[k];
            }
          } else {
            if (lineLength !== '' || k !== 0) {
              valToPrint = valToPrint + ' ' + lineArr[k];
              lineLength = lineLength + ' ' + lineArr[k];
              firstTime = false;
            } else if (lineArr[k] !== 0) {
              valToPrint = valToPrint + lineArr[k];
              lineLength = lineLength + lineArr[k];
              firstTime = false;
            }
          }
        } else {
          if (lineLength !== '' && remainder !== '') {
            valToPrint = valToPrint + ' ' + remainder;
            lineLength = lineLength + ' ' + remainder;
            firstTime = false;
          } else if (remainder !== '') {
            valToPrint = valToPrint + remainder;
            lineLength = lineLength + remainder;
            firstTime = false;
          }

          word = lineArr[k];
          if (lineLength !== '' && word !== '') word = lineLength + ' ' + word;
          else word = lineLength + word;

          if (word.length > maxLength) {
            if (lineArr[k].length > maxLength) {
              let count = Math.floor(lineArr[k].length / maxLength);
              for (let j = 1; j <= count; j++) {
                if (firstTime)
                  valToPrint = valToPrint + lineArr[k].substr(0, maxLength);
                else
                  valToPrint =
                    valToPrint + '\n' + lineArr[k].substr(0, maxLength);
                firstTime = false;
                lineLength = lineArr[k].substr(0, maxLength);
                lineArr[k] = lineArr[k].substr(maxLength);
              }
              remainder = lineArr[k];
            } else {
              if (firstTime) valToPrint = valToPrint + lineArr[k];
              else valToPrint = valToPrint + '\n' + lineArr[k];
              firstTime = false;
              lineLength = lineArr[k];
            }
          } else {
            if (lineLength !== '' || k !== 0) {
              valToPrint = valToPrint + ' ' + lineArr[k];
              lineLength = lineLength + ' ' + lineArr[k];
              firstTime = false;
            } else if (lineArr[k] !== '') {
              valToPrint = valToPrint + lineArr[k];
              lineLength = lineLength + lineArr[k];
              firstTime = false;
            }
          }
        }
      }
    } else {
      if (i === 0) valToPrint = valArr[i];
      else valToPrint = valToPrint + '\n' + valArr[i];
      lineLength = valArr[i];
    }
  }
  if (remainder !== '') {
    if (valToPrint !== '') valToPrint = valToPrint + '\n' + remainder;
    else valToPrint = valToPrint + remainder;
  }

  // $('#' + textAreaId).val(valToPrint);
  return valToPrint;
};

export const formatComPO = function (value) {
  return value.toUpperCase().split(/[\n,]+/);
};

export const encodeQuery = function (obj) {
  console.log('Before Encoding', obj);
  return queryString.stringify({ query: btoa(JSON.stringify(obj)) });
};

export const decodeQuery = function (query) {
  console.log(query);
  try {
    const obj = atob(query.query);
    console.log('After Decoding', obj);
    return JSON.parse(obj);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const isAlpha = function (str) {
  return /^[A-Za-z]+$/.test(str);
};

export const isNumber = function (str) {
  return /^\d+$/.test(str);
};

export const titleCase = function (str) {
  return _.map(str.split(' '), _.capitalize).join(' ');
};

export const getColumnWidth = (rows, accessor, headerText) => {
  const maxWidth = 450;
  const minWidth = 50;
  const magicSpacing = 10;
  const cellLength = Math.max(
    ...rows.map((row) => (`${row[accessor]}` || '').length),
    headerText.length
  );
  return Math.max(Math.min(maxWidth, cellLength * magicSpacing), minWidth);
};
export const calcRegion = function (currentUrl) {
  if (
    process.env.REACT_APP_COUNTRY_CODE &&
    process.env.REACT_APP_COUNTRY_CODE === 'US'
  ) {
    return process.env.REACT_APP_COUNTRY_CODE;
  } else if (
    process.env.REACT_APP_COUNTRY_CODE &&
    process.env.REACT_APP_COUNTRY_CODE === 'CN'
  ) {
    return process.env.REACT_APP_COUNTRY_CODE;
  }
  return currentUrl && currentUrl.indexOf('chinamatson') >= 0 ? 'CN' : 'US';
};

export const isEnableDocuSign = function () {
  return true;
};

export const isEnableDemoFeatures = function () {
  if (process.env.REACT_APP_DEMO && process.env.REACT_APP_DEMO === 'Y') {
    return true;
  } else {
    return false;
  }
};
