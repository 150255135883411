const initialState = {
  searchResult: {},
  searchCriteria: {},
  sortFields: [],
};

export default function bookingDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_BOOKING_CRITERIA':
      return { ...state, ...action.payload };
    case 'SET_BOOKING_SORT':
      return { ...state, ...{ sortFields: action.payload } };
    case 'SET_BOOKING_RESULT':
      return { ...state, ...action.payload, ...{ lastUpdateTs: new Date() } };
    case 'RESET_BOOKING_SEARCH':
      return initialState;
    default:
      return state;
  }
}
