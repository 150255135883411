import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const ProtectedRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(prop) =>
      user.username === '' ? (
        <Redirect to={{ pathname: '/' }} />
      ) : (
        <Component {...prop} />
      )
    }
  />
);

const ReduxConnectedCompoment = connect(
  (state) => ({ user: state.user }),
  {}
)(withRouter(ProtectedRoute));
export default ReduxConnectedCompoment;
