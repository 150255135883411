import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Footer from './../components/Footer';
import styles from './css/NoMatch404.module.css';

class Login extends Component {
  state = {
    txtUserName: this.props.user.username,
  };
  componentDidMount() {
    global.trackAnalyticsPageView(this.props.location.pathname);
  }

  render() {
    const { txtUserName } = this.state;
    if (this.props.subComp)
      return (
        <>
          <div className={styles.subComp}>
            <div className={styles.card}>
              <span className={styles.title}>No Containers Here</span>
              <span className={styles.text}>{this.props.message}</span>
              <img
                className={styles.img404}
                src={require('./../assets/images/404.png').default}
                alt="404"
              />
              {/* <span className={styles.text}>Sorry, We can't find that page!</span> */}
              <span className={styles.link}>
                Take me back to the{' '}
                <Link
                  to={txtUserName === '' ? '/' : '/search'}
                  href={txtUserName === '' ? '/' : '/search'}
                >
                  {txtUserName === '' ? 'Search' : 'Home Page'}
                </Link>
              </span>
            </div>
          </div>
        </>
      );
    else
      return (
        <>
          <div className={styles.mainContainer}>
            <div className={styles.card} style={{ width: 500 }}>
              <span className={styles.title}>No Containers Here</span>
              <img
                className={styles.img404}
                src={require('./../assets/images/404.png').default}
                alt="404"
              />
              <span className={styles.text}>
                Sorry, We can't find that page!
              </span>
              <span className={styles.link}>
                Take me back to the{' '}
                <Link
                  to={txtUserName === '' ? '/' : '/search'}
                  href={txtUserName === '' ? '/' : '/search'}
                >
                  {txtUserName === '' ? 'Search' : 'Home Page'}
                </Link>
              </span>
            </div>
            <Footer className={styles.footer} />
          </div>
        </>
      );
  }
}

const ReduxConnectedCompoment = connect(
  (state) => ({ user: state.user }),
  {}
)(withRouter(Login));

export default withTranslation('common')(ReduxConnectedCompoment);
