const initialState = null;

export default function selectedFiltersReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SELECTED_FILTERS':
      return { ...state, ...action.payload };
    case 'RESET_SELECTED_FILTERS':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
