const initialState = {
  vvds: [],
  packageTypes: [],
  containerTypes: [],
  serviceContracts: [],
  serviceTypes: [],
  portofLoad: [],
  portofLoading: [],
  gridPrefBR: null,
  gridPrefSI: null,
  gridPrefBLOI: null,
  gridPrefBLD: null,
  gridPrefSV: null,
  gridPrefVA: null,
  gridPrefCD: null,
  gridPrefBD: null,
};

export default function dropdownReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_DROPDOWN':
      return { ...state, ...action.payload };
    case 'RESET_DROPDOWN':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
