import React from 'react';
import { withRouter } from 'react-router-dom';

import styles from './css/Footer.module.css';

const Footer = (props) =>
  props.location.pathname !== '/' && (
    <footer
      className={[
        styles.footer,
        'page-footer',
        'font-small',
        'blue',
        props.className && props.className,
      ].join(' ')}
      style={{ zIndex: 7 }}
    >
      <div className={['footer-copyright', 'text-center', 'py-3'].join(' ')}>
        {`© ${process.env.REACT_APP_COPYRIGHT_YEAR} Matson Inc, All rights reserved. | `}
        <a className={styles.footerText} href="https://www.matson.com">
          Matson Home
        </a>
        {'| '}
        <a className={styles.footerText} href="https://www.matson.com">
          {' '}
          Terms & Conditions
        </a>
        <a
          className={styles.footerText}
          href={process.env.REACT_APP_CHINA_ICP_URL}
        >
          {`${process.env.REACT_APP_CHINA_ICP} `}
        </a>
      </div>
    </footer>
  );

export default withRouter(Footer);
